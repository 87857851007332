/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import styled from "styled-components";
import Pagination from "react-paginating";

import { navigate } from "gatsby";
import Layout from "../components/Layout";

// importing custom components
import ProductListItem from "../components/ProductListItem";
import CustomHeader from "../components/CustomHeader";
import CustomFooter from "../components/CustomFooter";
import Following from "../containers/FollowContainer";
import CategorySelector from "../components/CategorySelector";
import SlidingPane from "../components/SlidingPanel";
// importing grid system
import { Container, Row, Col } from "reactstrap";

// importing graphql
import { graphql } from "gatsby";
import SEO from "../seo";

import "../../static/fonts/stylesheet.css"

const Hmax = styled.div`
  font-family: Bebas Neue;
  font-size: 54px;
  margin-top: 16px;
  text-align: center;
  color: #000;
  @media only screen and (min-width: 376px) and (max-width: 768px) {
    font-size: 44px;
  }
`;

const SelectorContainer = styled.div`
  margin-top: 44px;
  margin-bottom: 30px;
  align-items: center;
`;

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 140px;
  @media only screen and (max-width: 768px) {
    justify-content: center;
    margin-top: 100px;
  }
`;

const defaultBtnStyle = {
  width: 44,
  height: 44,
  border: "none",
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "white",
  fontFamily: "Bebas Neue",
  cursor: "pointer",
  fontSize: 16
};

const activeBtnStyle = {
  width: 44,
  height: 44,
  textAlign: "center",
  textDecoration: "none",
  border: "solid 1px #000000",
  backgroundColor: "white",
  fontFamily: "Bebas Neue",
  cursor: "pointer",
  fontSize: 16
};

const arrowBtnStyle = {
  width: 44,
  height: 44,
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "white",
  fontFamily: "Bebas Neue",
  color: "#1b1b1b",
  fontSize: 16,
  border: "solid 2px #f7b4cc",
  cursor: "pointer",
};

const AllProductPage = props => {
  const allProducts = props.data.allContentfulProduct.nodes;

  const [selectedProductIndex, setSelectedProductIndex] = useState(0);

  const [isInfoPaneOpen, setIsInfoPaneOpen] = useState(false);

  const handlePageChange = (page, e) => {
    if (page === 0 || page === 1) {
      navigate(`/products/all/`);
    } else {
      navigate(`/products/all/${page}`);
    }
  };

  return (
    <Layout id="slidingPane">
      <SEO title="Products" />
      <CustomHeader />
      <Container style={{ marginTop: 105 }}>
        <Row>
          <Col lg={12} sm={12} md={12} xl={12}>
            <Hmax>All Products</Hmax>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectorContainer>
              <CategorySelector />
            </SelectorContainer>
          </Col>
        </Row>
        <Row>
          {allProducts.map((item, index) => {
            return (
              <Col lg={4} xl={3} md={6} sm={12} key={item.id}>
                <ProductListItem
                  data={item}
                  onClick={() => {
                    setSelectedProductIndex(index);
                    setIsInfoPaneOpen(true);
                  }}
                />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <Pagination
              total={props.pageContext.total}
              limit={props.pageContext.limit}
              pageCount={3}
              currentPage={props.pageNumber}
            >
              {({
                pages,
                currentPage,
                hasNextPage,
                hasPreviousPage,
                previousPage,
                nextPage,
                totalPages,
                getPageItemProps
              }) => (
                  <PaginationContainer>
                    {hasPreviousPage && (
                      <button
                        {...getPageItemProps({
                          pageValue: previousPage,
                          onPageChange: handlePageChange,
                          style: arrowBtnStyle
                        })}
                      >
                        {"<"}
                      </button>
                    )}

                    {pages.map(page => {
                      let activePage = defaultBtnStyle;
                      if (currentPage === page) {
                        activePage = activeBtnStyle;
                      }
                      return (
                        <button
                          {...getPageItemProps({
                            pageValue: page,
                            key: page,
                            style: activePage,
                            onPageChange: handlePageChange
                          })}
                        >
                          {page}
                        </button>
                      );
                    })}

                    {hasNextPage && (
                      <button
                        {...getPageItemProps({
                          pageValue: nextPage,
                          onPageChange: handlePageChange,
                          style: arrowBtnStyle
                        })}
                      >
                        {">"}
                      </button>
                    )}
                  </PaginationContainer>
                )}
            </Pagination>
          </Col>
        </Row>
      </Container>
      <Following />
      <CustomFooter />
      <SlidingPane
        product={allProducts[selectedProductIndex]}
        isInfoPaneOpen={isInfoPaneOpen}
        setIsInfoPaneOpen={setIsInfoPaneOpen}
      />
    </Layout>
  );
};

export default AllProductPage;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulProduct(
      filter: { image: { id: { ne: null } } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        brewery
        price
        alcohol_percentage
        description{
          description
        }
        type 
        packaging
        image {
          id
          fluid(maxWidth: 613) {
            ...GatsbyContentfulFluid
          }
           file {
            url
          }
        }
      }
    }
  }
`;
